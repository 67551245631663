<template>
    <div>
        <div class="head-title">
            <span @click="onHomePage" style="float: left;font-size: 12px;padding-top: 5px;"> < 返回</span>
            <span style="padding-right: 30px;">访客资料填写 ( {{num}} / 3 )</span>
        </div>
        <div class="head-img">
            <div class="head-img-box">
                <van-uploader :max-count="1"
                              :disabled="proType2 == 2 ? false : true"
                              v-model="fileList"
                              :multiple="false"
                              :before-read="beforeRead"
                              :after-read="afterRead"
                              :before-delete="delUploadImg"
                              upload-text="图片采集">
                </van-uploader>
                <div v-if="proType1 == 1 && proType2 != 2" style="color:red;font-size: 12px;">
                    未开通人行访客
                </div>
                <div v-else style="color:red;font-size: 12px;" @click="handleShowImg">
                    上传照片需要符合一定规范，点击查看范例&nbsp;&nbsp;&nbsp;&nbsp;>
                </div>
            </div>
        </div>
        <van-form v-show="showForm" ref="form">
            <van-cell-group inset>
                <div class="form-box" v-show="active == 0">
                    <!-- 目的地 -->
                    <van-field
                            v-model="visibleForm.projectName"
                            readonly
                            required
                            name="picker"
                            label="目的地"
                            placeholder="点击目的地"
                            :rules="[{ required: true}]"
                    />
                    <!--@click="handleShow"  <van-popup v-model="showPicker1" position="bottom">
                        <van-search v-model="name" placeholder="请输入访问目的地" @input="onSearch"></van-search>
                        <van-picker
                                :columns="columns"
                                value-key="id"
                                show-toolbar
                                @confirm="onConfirm1"
                                @cancel="showPicker1 = false">
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.projectName }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>-->
                    <!-- 姓名 -->
                    <van-field
                            v-model="visibleForm.visitorName"
                            name="姓名"
                            required
                            placeholder="请输入姓名"
                            :rules="[{ required: true}]"
                    >
                        <!-- 使用label插槽 -->
                        <template #label>
                            <!-- 自定义样式label-flex -->
                            <div class="label-flex">
                                <span class="label-justify">姓名</span>
                            </div>
                        </template>
                    </van-field>
                    <!-- 手机号 -->
                    <van-field
                            v-model="visibleForm.visitorPhone"
                            name="手机号"
                            label="手机号"
                            required
                            placeholder="请输入手机号码"
                            type="digit"
                            :rules="[{ required: true}]"
                    />
                    <!-- 证件号码 -->
                    <van-field
                            v-model="visibleForm.visitorIdCard"
                            name="证件号"
                            label="证件号"
                            placeholder="需现场二次认证的场所为“必填"
                    />
                    <!-- 被访人 -->
                    <van-field
                            v-model="visibleForm.visiteeName"
                            name="被访人"
                            label="被访人"
                            placeholder="请输入被访人姓名"
                            required
                            is-link
                            :rules="[{ required: true}]"
                            @click="handleInterviewee"
                    />
                    <van-popup v-model="showPicker5" position="bottom">
                        <van-search v-model="intervieweeName" placeholder="请输入被访人姓名" @input="handleSearch"></van-search>
                        <van-picker :loading="loading"
                                    :columns="userInfoList"
                                    value-key="id"
                                    show-toolbar
                                    @confirm="handleConfirm"
                                    @cancel="showPicker5 = false">
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.name }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <!-- 拜访事由 -->
                    <van-field
                            v-model="visibleForm.visitReason"
                            name="事 由"
                            placeholder="请输入拜访事由">
                        <!-- 使用label插槽 -->
                        <template #label>
                            <!-- 自定义样式label-flex -->
                            <div class="label-flex">
                                <span class="label-justify">事由</span>
                            </div>
                        </template>
                    </van-field>
                    <!--                    <van-field-->
                    <!--                            v-model="visibleForm.visitReason"-->
                    <!--                            is-link-->
                    <!--                            name="拜访事由"-->
                    <!--                            label="拜访事由"-->
                    <!--                            placeholder="请输入拜访事由"-->
                    <!--                            @focus="onFocus"-->
                    <!--                            @change="onChange"-->
                    <!--                            @blur="onBlur"-->
                    <!--                    />-->
                </div>
                <div class="form-box" v-show="active == 1">
                    <!-- 车牌号 -->
                    <van-field v-if="proType1 == 1"
                               v-model="visibleForm.visitorLicensePlate"
                               name="车牌号码"
                               label="车牌号码"
                               placeholder="请输入车牌号码"
                    />
                    <!-- 随访人数 -->
                    <van-field
                            v-model="visibleForm.followerCnt"
                            name="随访人数"
                            label="随访人数"
                            placeholder="请输入随访人数"
                            type="digit"
                    />
                    <!-- 携带物品 -->
                    <van-field
                            v-model="visibleForm.carryItem"
                            name="携带物品"
                            label="携带物品"
                            placeholder="请输入携带物品"
                    />
                    <!-- 拜访起始时间 -->
                    <van-field
                            v-model="visibleForm.startTime"
                            is-link
                            readonly
                            clearable
                            required
                            @focus="start"
                            name="datetimePicker"
                            input-align="center"
                            label="起始时间"
                            placeholder="点击选择开始时间"
                            @click="showPicker2 = true"
                            :rules="[{ required: true}]"
                    />
                    <!-- 拜访截止时间 -->
                    <van-field
                            v-model="visibleForm.endTime"
                            is-link
                            readonly
                            clearable
                            required
                            @focus="end"
                            input-align="center"
                            name="datetimePicker"
                            label="截止时间"
                            placeholder="点击选择结束时间"
                            @click="showPicker3 = true"
                            :rules="[{ required: true }]"
                    />
                    <!-- 开始时间控件 -->
                    <van-popup v-model="showPicker2" position="bottom">
                        <van-datetime-picker
                                type="datetime"
                                v-model="currentDate1"
                                :min-date="minDate1"
                                :max-date="maxDate1"
                                @confirm="onConfirm2"
                                @cancel="showPicker2 = false"
                        />
                    </van-popup>
                    <!-- 结束时间控件 -->
                    <van-popup v-model="showPicker3" position="bottom">
                        <van-datetime-picker
                                type="datetime"
                                v-model="currentDate2"
                                :min-date="minDate2"
                                :max-date="maxDate2"
                                @confirm="onConfirm3"
                                @cancel="showPicker3 = false"
                        />
                    </van-popup>
                </div>
                <!-- 头像
                <van-field v-if="proType2 == 2" name="uploader" label="头像" required
                           :rules="[{ required: true, message: '请选择头像' }]">
                    <template #input>
                        <div style="display: flex;flex-direction: column;">
                            <van-uploader :max-count="1"
                                          v-model="fileList"
                                          :multiple="false"
                                          :before-read="beforeRead"
                                          :after-read="afterRead"
                                          :before-delete="delUploadImg"
                                          upload-icon="plus">
                            </van-uploader>
                            <div style="color:red;" @click="handleShowImg">
                                上传照片需要符合一定规范，点击查看范例&nbsp;&nbsp;&nbsp;&nbsp;>
                            </div>
                        </div>
                    </template>
                </van-field>-->
            </van-cell-group>
            <div class="div-btn1" v-show="active == 0">
                <van-button class="btn1" block type="info" @click="onNext">
                    下一步
                </van-button>
            </div>
            <div class="div-btn2" v-show="active == 1">
                <van-button class="bbtn" style="margin: 10px;" block type="default" @click="onBack">
                    上一步
                </van-button>
                <van-button class="btnn" block type="primary" @click="onSubmit" :loading="btnLoading"
                            loading-text="提交中...">
                    提 交
                </van-button>
            </div>
            <div class="select" v-if="showPicker4" @mouseenter="flag= true" @mouseleave="flag= false">
                <div class="select-div">
                    <div style="color: #969799;" class="btn" @click="onClose"> 取消</div>
                    <div style="color: #576b95;" class="btn" @click="onClose"> 确认</div>
                </div>
                <div class="select-info">
                    <div v-for="(item, index) in reasonList"
                         :key="index"
                         @click.stop="onClick(item)">
                        {{ item }}
                    </div>
                </div>
            </div>
        </van-form>
        <div v-show="showSuccess" class="info-box">
            <div class="info-box-icon">
                <van-icon size="75" color="#fff" name="success"/>
            </div>
            <p>操作成功</p>
            <div class="div-btn">
                <van-button class="van-btn" block type="warning" @click="onCancel">
                    关闭当前页面
                </van-button>
                <van-button block type="default" @click="onHomePage">
                    返回主页面
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Icon,
        Form,
        Field,
        Button,
        Search,
        Popup,
        Cell,
        Toast,
        Uploader,
        CellGroup,
        Picker,
        DatetimePicker,
        ActionSheet, ImagePreview
    } from 'vant';
    import {addAppointment, getProjectSearch, queryUserList, uploadFile} from "../api/interface";
    import Compressor from 'compressorjs';
    import preventBack from 'vue-prevent-browser-back';//组件内单独引入
    import debounce from "../directives/debounce";

    export default {
        name: "visitorRegistration",
        components: {
            [Button.name]: Button,
            [Form.name]: Form,
            [Icon.name]: Icon,
            [Field.name]: Field,
            [Search.name]: Search,
            [Popup.name]: Popup,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Uploader.name]: Uploader,
            [Picker.name]: Picker,
            [DatetimePicker.name]: DatetimePicker,
            [ActionSheet.name]: ActionSheet,
        },
        // mixins: [preventBack],//注入  阻止返回上一页
        data() {
            return {
                active: 0,
                num: 1,
                visibleForm: {
                    visitorName: "",
                    visitorPhone: "",
                    visitorIdCard: "",
                    visitorLicensePlate: "",
                    visitorAvatar: "",
                    visitReason: "",
                    passCardNum: "",
                    doorwayIds: "",
                    startTime: "",
                    endTime: "",
                    projectId: "",
                    projectName: "",
                    visiteeName: "",
                    visiteeNo: "",
                    carryItem: "",
                    id: "",
                },
                value: "",
                name: "",
                showPicker1: false,
                showPicker2: false,
                showPicker3: false,
                showPicker4: false,
                showPicker5: false,
                flag: false,
                fileList: [],
                columns: [],
                reasonList: ['拜访', '送货', '维护'],
                minDate1: new Date(1900, 0, 1), // 最小的可选的日期
                maxDate1: new Date(3000, 0, 1), // 最大的可选日期
                currentDate1: new Date(),
                minDate2: new Date(1900, 0, 1), // 最小的可选的日期
                maxDate2: new Date(3000, 0, 1), // 最大的可选日期
                currentDate2: new Date(),
                startPicker: "",
                endPicker: "",
                projectType: [],
                proType1: "",
                proType2: "",
                avatarList: [require('../assets/img/example.jpg')],
                showSuccess: false,
                showForm: true,
                userInfoList: [],
                intervieweeName: "",
                loading: false,
                btnLoading: false
            };
        },
        mounted() {
            this.visibleForm.projectName = this.$route.query.projectName;
            this.visibleForm.projectId = this.$route.query.parkingId;
            this.proType1 = this.$route.query.type1;
            this.proType2 = this.$route.query.type2;
        },
        methods: {
            onSubmit: debounce(function () {
                // 全局表单验证
                this.$refs.form.validate().then(() => {
                    let param = {
                        visitorName: this.visibleForm.visitorName,
                        visitorPhone: this.visibleForm.visitorPhone,
                        visitorLicensePlate: this.visibleForm.visitorLicensePlate,
                        visitorAvatar: this.visibleForm.visitorAvatar,
                        visiteeName: this.visibleForm.visiteeName,
                        visitReason: this.visibleForm.visitReason,
                        startTime: this.visibleForm.startTime,
                        endTime: this.visibleForm.endTime,
                        projectId: this.visibleForm.projectId,
                        visitorIdCard: this.visibleForm.visitorIdCard,
                        followerCnt: this.visibleForm.followerCnt,
                        carryItem: this.visibleForm.carryItem,
                        needVerify: true
                    }
                    this.btnLoading = true;
                    addAppointment(param).then((res) => {
                        this.btnLoading = false;
                        if (res.data.code == "SUCCESS") {
                            Toast.success(res.data.message);
                            this.showSuccess = true;
                            this.showForm = false;
                            this.num = 3;
                        } else {
                            Toast.fail(res.data.message);
                        }
                    })
                }).catch(() => {
                    Toast({message: '必填项不能为空，提交失败！', duration: 2000})
                })
            }, 2000),
            onCancel() {
                this.$router.push('/visitorRecord')
            },
            onHomePage() {
                this.$router.push("/visitor?parkingId=" + this.$route.query.parkingId + "&projectName=" + this.$route.query.projectName + "&type1=" + this.$route.query.type1 + "&type2=" + this.$route.query.type2)
            },
            onNext() {
                if (this.visibleForm.visitorName && this.visibleForm.projectName && this.visibleForm.visitorPhone && this.visibleForm.visitorPhone) {
                    this.active = 1;
                    this.num = 2;
                } else {
                    Toast({message: '必填项不能为空！', duration: 2000});
                }
            },
            onBack() {
                this.active = 0;
                this.num = 1;
            },
            handleShow() {
                this.columns = [];
                this.showPicker1 = true;
                this.onSearch();
            },
            onSearch() {
                getProjectSearch({name: this.name}).then(res => {
                    this.columns = res.data.data;
                }).catch(err => { //报错进这个
                    console.log(err)
                });
            },
            onConfirm1(value) {
                this.showPicker1 = false;
                this.name = "";
                this.visibleForm.projectName = value.projectName;
                this.visibleForm.projectId = value.id;
                let projectType = value.projectType;
                this.projectType = [];
                if (projectType) {
                    projectType.forEach((e) => {
                        this.projectType.push(e.type);
                    })
                    this.proType1 = this.projectType.filter(item => item === 1).toString();
                    this.proType2 = this.projectType.filter(item => item === 2).toString();
                }
            },
            // 选择开始时间
            start(e) {
                this.showPicker2 = true;
            },
            // 选择结束时间
            end(e) {
                this.showPicker3 = true;
            },
            getMaxDate() {
                let year = new Date().getFullYear();
                this.minDate1 = new Date(year - 1, 0, 1);
                this.maxDate1 = new Date(year + 1, 11, 31);
                this.minDate2 = new Date(year - 1, 0, 1);
                this.maxDate2 = new Date(year + 1, 11, 31);
            },
            //日期格式
            formatDate(date) {
                const dt = new Date(date);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + '').padStart(2, '0');
                const d = (dt.getDate() + '').padStart(2, '0');
                const hh = (dt.getHours() + '').padStart(2, '0');
                const mm = (dt.getMinutes() + '').padStart(2, '0');
                const ss = (dt.getSeconds() + '').padStart(2, '0');
                return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
            },
            onConfirm2(date) {
                this.startPicker = this.formatDate(date);
                //用户先选择结束时间时取两者的值进行比较判断
                if (new Date(this.startPicker) > new Date(this.endPicker)) {
                    //开始时间大于结束时间被选中的状态返回false，选中的时间不渲染，datePicker不关闭
                    this.showPicker2 = false;
                    this.visibleForm.startTime = "";
                    Toast({
                        message: '结束日期不能在开始日期之前',
                        position: 'bottom'
                    });
                } else {
                    this.showPicker2 = false;
                    this.visibleForm.startTime = this.formatDate(this.currentDate1);
                }
            },
            onConfirm3(date) {
                this.endPicker = this.formatDate(date);
                if (new Date(this.startPicker) > new Date(this.endPicker)) {
                    //开始时间大于结束时间被选中的状态返回false，选中的时间不渲染，datePicker不关闭
                    this.showPicker3 = false;
                    this.visibleForm.endTime = "";
                    Toast({
                        message: '结束日期不能在开始日期之前',
                        position: 'bottom'
                    });
                } else {
                    this.showPicker3 = false;
                    this.visibleForm.endTime = this.formatDate(this.currentDate2);
                }
            },
            onFocus() {
                this.showPicker4 = true;
            },
            onClick(val) {
                this.visibleForm.visitReason = val;
                this.showPicker4 = false;
                this.flag = false;
            },
            onChange() {
                this.showPicker4 = false;
            },
            onBlur() {
                this.showPicker4 = this.flag;
            },
            onClose() {
                this.showPicker4 = false;
                this.flag = false;
            },
            onConfirm4(value) {
                this.visibleForm.visitReason = value;
                this.showPicker4 = false;
            },
            // 返回布尔值
            beforeRead(file) {
                if (file instanceof Array) {
                    //判断是否是数组
                    file.forEach((v) => {
                        this.checkFile(v);
                    });
                } else {
                    this.checkFile(file);
                }
                return true;
            },
            //图片类型检查
            checkFile(file) {
                const format = ["jpg", "png", "jpeg"];
                const index = file.name.lastIndexOf(".");
                const finalName = file.name.substr(index + 1);
                if (!format.includes(finalName.toLowerCase())) {
                    Toast("请上传" + format.join(",") + "格式图片");
                    return false;
                }
            },
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                if (file instanceof Array) {
                    file.map((v) => {
                        v.status = "uploading";
                        v.message = "上传中...";
                        this.uploadImg(v);
                    });
                } else {
                    file.status = "uploading";
                    file.message = "上传中...";
                    this.uploadImg(file);
                }
            },
            //上传
            uploadImg(file) {
                let that = this;
                new Compressor(file.file, {
                    success(result) {
                        // Blob 转换为 File
                        const files = new window.File([result], file.name, {type: file.type});
                        const formData = new FormData();
                        formData.append('file', files);
                        uploadFile(formData).then(res => {
                            if (res.data.code == "SUCCESS") {
                                if (res.data.data) {
                                    if (file instanceof Array) {
                                        //判断是否是数组
                                        file.map((v, i) => {
                                            v.status = "success";
                                            v.message = "";
                                            v.url = res.data.data[i];
                                        });
                                    } else {
                                        file.status = "success";
                                        file.message = "";
                                        file.url = res.data.data;
                                        that.visibleForm.visitorAvatar = res.data.data;
                                    }
                                }
                            } else {
                                Toast({
                                    message: res.data.message,
                                    position: 'bottom',
                                    type: 'fail'
                                });
                            }
                        }).catch(err => { //报错进这个
                            console.log(err)
                        });
                    },
                    error(err) {
                        console.log(err.message);
                    },
                });
            },
            //文件上传
            // afterRead(file) {
            //     let content = file;
            //     let formData = new FormData();
            //     formData.append("file", content.file);
            //     uploadFile(formData).then(res => {
            //         if (res.data.code == "SUCCESS") {
            //             this.fileList = res.data.data;
            //             this.visibleForm.visitorAvatar = this.fileList;
            //         } else {
            //             Toast({
            //                 message: res.data.message,
            //                 position: 'bottom',
            //                 type: 'fail'
            //             });
            //         }
            //     }).catch(err => { //报错进这个
            //         console.log(err)
            //     });
            // },
            //删除
            delUploadImg(item) {
                this.fileList = this.fileList.filter((v) => v.url != item.url);
            },
            //头像放大示例
            handleShowImg() {
                ImagePreview({
                    images: this.avatarList,
                    showIndex: false
                })
            },
            handleInterviewee() {
                this.showPicker5 = true;
                this.handleSearch();
            },
            handleSearch() {
                let param = {
                    projectId: this.visibleForm.projectId,
                    name: this.intervieweeName,
                    fuzzyMatch: true
                }
                this.loading = true;
                queryUserList(param).then((res) => {
                    this.loading = false;
                    this.userInfoList = res.data.data;
                    // if (this.userInfoList.length > 0) {
                    //     this.userInfoList.forEach(item => {
                    //         if (item.name == this.visibleForm.visiteeName) {
                    //             this.visitee = item.name + ' | ' + item.department + ' | ' + item.phone;
                    //         }
                    //     })
                    // } else {
                    //     this.visitee = this.visibleForm.visiteeName;
                    // }
                }).catch(error => {
                    console.log(error);
                });
            },
            handleConfirm(value) {
                this.showPicker5 = false;
                this.intervieweeName = "";
                this.visibleForm.visiteeName = value.name;
            }
        }
    }
</script>

<style scoped>
    >>> .van-uploader__upload {
        background-color: unset;
        width: 140px;
        height: 140px;
        margin: 0 auto;
    }

    >>> .van-uploader__preview {
        width: 140px;
        height: 140px;
        margin: 0 auto;
    }

    >>> .van-uploader__preview-image {
        width: 140px;
        height: 140px;
    }

    >>> .van-uploader__upload-icon {
        color: #EC7955;
        font-size: 50px;
    }

    >>> .van-uploader__upload-text {
        color: #EC7955;
        font-size: 13px;
    }

    .van-cell {
        padding: 18px;
    }

    .label-flex {
        display: flex;
        height: 20px;
        overflow: hidden;
    }

    .label-flex .label-justify {
        flex: 1;
        text-align: justify;
        text-align-last: justify;
        margin-right: 45px;
    }

    .label-flex .label-justify ::after {
        content: "";
        width: 100%;
        display: inline-block;
        height: 0;
    }


    .head-title {
        background: linear-gradient(-30deg, #e25744, #e86f50);
        color: #FFFFFF;
        font-size: 16px;
        padding: 10px 20px;
    }

    .head-img {
        background-color: rgb(220 218 219);
        padding: 13px 0;
    }

    .head-img-box {
        margin: 0 auto;
        width: 150px;
        height: 170px;
        border: 1px solid #FD4A1B;
        padding: 10px;
    }

    .form-box {
        height: 360px;
    }

    .div-btn1 {
        margin: 15px;
        display: flex;
    }

    .div-btn2 {
        margin: 15px;
        display: flex
    }

    .div-btn2 .bbtn {
        margin: 10px;
    }

    .div-btn2 .btnn {
        background: linear-gradient(-30deg, #EC7955, #DC5040);
        border: none;
        margin: 10px;
    }

    .div-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 55px 20px 0 0;
    }

    .div-btn .van-button {
        font-weight: 500;
        margin: 10px;
    }

    .div-btn .van-btn {
        background: linear-gradient(-30deg, #e85744, #e86f50);
    }

    .info-box {
        display: flex;
        flex-direction: column;
        margin: 10px;
    }

    .info-box p {
        font-size: 18px;
        display: block;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        margin: 20px;
    }

    .info-box-icon {
        background-color: #05fa05;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 55px auto 0;
    }

    .btn1 {
        background: linear-gradient(-30deg, #EC7955, #DC5040);
        /*border-radius: 27px;*/
        border: none;
        font-weight: 500;
        margin: 10px;
    }

    .btn {
        background: none;
        border-radius: 27px;
        border: none;
        font-weight: 500;
        color: #222222;
        font-size: 14px;
    }

    .select {
        z-index: 2022;
        bottom: 0;
        left: 0;
        width: 100%;
        position: fixed;
        height: 250px;
        max-height: 100%;
        overflow-y: auto;
        background-color: #fff;
        font-size: 16px;
    }

    .select-div {
        margin: 20px;
        display: flex;
        justify-content: space-between;
    }

    .select-info {
        line-height: 50px;
    }

    .preview-cover {
        position: absolute;
        bottom: 0;
        box-sizing: border-box;
        width: 100%;
        padding: 4px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background: rgba(0, 0, 0, 0.3);
    }
</style>
